.inguma-button {
  display: flex;
  max-width: fit-content;
  align-items: center;
  padding: 0.4em;
}

.inguma-button:hover {
  border: solid 1px;
}

.inguma-button img {
  margin-right: 0.7em;
}
