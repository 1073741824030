.react-datepicker__header {
  background-color: white;
}

.ui.form .react-datepicker__header select {
  min-width: fit-content;
  border: none;
  margin-right: 0.3rem;
  margin-left: 0.3rem;
  font-size: 13pt;
}

.react-datepicker__day-name,
.react-datepicker__day {
  margin: 0.4rem;
  font-size: 13pt;
}

.DayPickerNavigation_button {
  display: flex;
  align-items: center;
}

.DayPickerNavigation_button svg {
  vertical-align: middle;
}

button.react-datepicker__close-icon::after {
  background-color: black;
}

div.react-datepicker__day--selected {
  background-color: black;
}

.ui.form .react-datepicker__input-container input[type='text'] {
  padding-left: 1rem;
}
